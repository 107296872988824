.container {
    margin-inline-start: 250px;
    padding-block: 1vw;
}

.contentCard {
    margin-block: 3vw;
    border: 1px solid gray;
    border-radius: 10px;
    width: 80%;
    margin-inline: auto;
    padding: 2vw;
}

.projectDataTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .projectDataTable th,
  .projectDataTable td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .projectDataTable th {
    background-color: #f2f2f2;
    color: rgb(216, 15, 15);
  }
  
  .projectDataTable tr:nth-child(even) {
    background-color: #f9f9f9;
    font-weight: bold; 
    font-size: 18px;   
    font-weight: bold; 
  }
  
  .projectDataTable tr:hover {
    background-color: #ADD8E6; /* Light blue hover effect */
  }
  
  
.red {
    color: rgb(216, 15, 15);
    font-weight: bold; 
    font-size: 18px;   
    font-weight: bold;  
}

.progress-bar {
    display: flex;
    align-items: center;
    height: 30px;  
    margin-bottom: 10px;  
    background-color: #f5f5f5;  
    border-radius: 5px;  
    border: 1px solid gray;
    overflow: hidden;  
  }
  
  
  /* Text inside the progress bar */
  .progress-bar p {
    margin: 0 15px; 
    font-size: 15px;
    color: #c20a0a; 
  }
  
  /* Progress indicator */
  .progress {
    height: 100%;
    background-color: #007bff; /* Primary color for progress bar */
    border-radius: 5px;
    transition: width 0.3s ease-in-out;
    text-align: center; /* Center align text inside progress bar */
    line-height: 30px; /* Vertically center text */
    color: white; /* Text color inside progress bar */
    font-weight: bold; /* Make text bold for better visibility */
  }
  
/* Responsive styles for mobile screens */
@media (max-width: 768px) {
    .container {
        margin-inline-start: 0; /* Remove left margin on smaller screens */
        padding-block: 2vw; /* Increase padding for smaller screens */
    }

    .contentCard {
        width: 95%; /* Increase width for better fit on smaller screens */
        padding: 4vw; /* Adjust padding for smaller screens */
        margin-block: 2vw; /* Reduce margin for smaller screens */
    }

    .red {
        font-size: 16px; /* Adjust font size for better readability on smaller screens */
    }
}
