.container {
    text-align: center;
    margin-inline-start: 250px;
    margin-top: 2vw;
}

.contentCard {
    /* margin-block: 3vw; */
    border: 1px solid gray;
    border-radius: 10px;
    width: 50%;
    margin-inline: auto;
    padding: 2vw;
}

.contentCon .contentCard {
    /* margin-inline-start: 0vw; */
    border: none;
    padding: none
}

.dateInput {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.projectSelection {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.fetchBtn {
    margin-top: 2vw;
    padding: 0.7em;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 12px;
    font-weight: 700;
    font-size: medium;
    background: #280404;
    color: #fff;
    cursor: pointer;
    position: relative
}

@media (max-width:768px) {
    .container {
        margin-inline-start: 0px;
        padding-top: 25vw;
    }

    .contentCard {
        margin-top: 11vw !important;
        margin-inline-start: unset;
        margin-inline: auto;
        width: 90%;
        display: block !important;
        margin-bottom: 10vw;
    }

    .projectCompletion .contentCard .sel {
        width: 100% !important;
    }
}