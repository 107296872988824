body {
  margin: 0;
  padding: 0;
}

.loginContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center content vertically and horizontally */
  padding: 2em;
  box-sizing: border-box;
  overflow: hidden;
}

.Card {
  margin-top: 7vh;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
}

.loginContainer img {
  max-width: 600px;
  max-height: 600px;
}

.fields {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  padding: 0 40px;
}

.fields input{
  margin-top: 1em;
  padding-left: 0.7em;
  padding-right: 0.7em;
  padding-top: 0.7em;
  padding-bottom: 0.7em;
  min-width: 25em;
  border: 1px solid #ccc;
  border-radius: 12px;
  margin-top: 1em;
  font-weight: 500 !important;
  font-size: medium;
  box-shadow: 0 0 40px rgba(0,0,0,.10196078431372549);
}



.fields button {
  padding: 0.7em;
  min-width: 26em;
  border: 1px solid #ccc;
  border-radius: 12px;
  margin-top: 3em;
  font-weight: 700;
  font-size: medium;
  background: linear-gradient(to bottom, #C6212F, #911F5C);
  color: #fff;
  cursor: pointer;
}

.errorMessage{
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  background-color: #F8D7DA;
  padding: 0;
  margin-top: 1em;
  width: 100%;
}
.errorMessage p{
  color: #971E24;
}

@media (max-width: 767px) {
  .loginContainer {
    margin-top: 20vh;
    padding: 1em; /* Adjust padding for smaller screens */
  }

  .loginContainer img {
    max-width: 300px;
    max-height: 300px;
  }

  .Card {
    margin-top: 5vh; /* Adjust margin for smaller screens */
  }

  .fields {
    padding: 0 30px; /* Adjust padding for smaller screens */
  }
  .fields input {
  min-width: 13em;
  }
  .fields button {
    min-width: 15em;
  }
}
