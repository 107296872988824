.sidebar {
  background-color: #111;
  color: #fff;
  position: fixed;
  width: 250px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  transition: transform 0.3s ease;
  transform: translateX(0); /* Ensure sidebar is visible by default */
  overflow: hidden;
}

.logo {
  display: flex;
  justify-content: center;
  padding: 30px 25px;
}

.logo img {
  height: 3em;
  width: auto;
  padding-bottom: 1em;
}

.userInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.userInfoLine {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 401;
  line-height: 1.5;
  letter-spacing: -0.22px;
}

.bmenuItem {
  list-style: none;
  width: 100%;
  padding: 0;
  margin: 0;
}

.AccessTimeIcon {
  font-size: 23px;
  align-items: center;
  margin-right: 12px;
}

.bmenuItemlink {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  text-decoration: none;
  color: #fff;
  font-family: poppins;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.264px;
  background-color: transparent;
  transition: background-color 0.3s ease, color 0.3s ease;
  position: relative;
  margin-top: 1vw;
}

.bmenuItemlink.clicked {
  background-color: rgba(79, 79, 79, 1);
}

.bmenuItemlink.clicked::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 10px;
  height: 100%;
  background-color: pink;
}

.logoutButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  text-decoration: none;
  color: #da0808;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.264px;
  background-color: transparent;
  transition: background-color 0.3s ease, color 0.3s ease;
  position: relative;
  margin-top: 2vw;
  cursor: pointer;
  text-align: center;
  bottom: 20px;
}

.logoutButton p {
  margin: 0;
  margin-left: 5px;
}

.logoutButton svg {
  font-size: 24px;
}

.icon {
  display: none;
}

.smenuItem {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2vw;
  text-align: center;
}

.smenuContainer {
  display: none;
  background-color: #f4cfd5;
  flex-direction: column;
  width: 100%;
  gap: 1vw;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 100px;
  left: 0;
  z-index: 999;
}

.smenuItemlink {
  padding: 10px;
  text-decoration: none;
  color: #180000;
  transition: background-color 0.3s ease, color 0.3s ease;
  position: relative;
  justify-content: center;
}

@media (max-width: 767px) {
  .sidebar {
    background-color: #180000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #fff;
    position: fixed;
    width: 100%;
    height: 100px;
    overflow-y: auto;
  }

  .logo {
    display: flex;
    justify-content: center;
    padding: 30px 25px;
  }

  .logo img {
    height: 2em;
  }

  .userInfo {
    font-size: small;
    display: flex;
    padding-top: 20px;
  }

  .AccessTimeIcon {
    position: relative;
    top: 5px;
    font-size: 18px;
    margin-right: 8px;
  }

  .userInfoLine {
    margin: 4px 0;
  }

  .bmenuItem {
    display: none;
  }

  .logoutButton {
    display: none;
  }

  .icon {
    display: block;
    padding: 14px;
  }

  .smenuContainer {
    display: flex;
  }
}
