body {
  margin: 0;
  padding: 0;
  font-family: poppins;
}

.container {
  display: flex;
}

.contentCard {
  margin-left: 20em;
  margin-right: 3em;
  width: 100%;
}

/* Day Selector Styling */
.daySelector {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid #dadada;
  border-radius: 12px;
}

.daySelector button {
  flex: 1;
  padding: 0.5em;
  margin-right: 5px;
  margin-left: 5px;
  border: none;
  background-color: #fff;
  color: rgb(36, 36, 36);
  cursor: pointer;
  border-radius: 12px;
}

.HoursSelector {
  padding-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

/* Active button style when clicked */
.daySelector button.active {
  background-color: #f4cfd5;
  color: rgb(216, 15, 15);
}

.selected p {
  padding: 10px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
}

.select {
  display: inline-flex;
  flex: 1;
  padding: 0.5rem 2rem;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #d9d9d9;
  font-size: 1rem;
  gap: 1rem;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: #f4cfd5;
  color: rgb(216, 15, 15);
}

.ToDo {
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 30px;
  border: 1px solid #dadada;
  border-radius: 12px;
}

.ToDo h2 {
  color: #111;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.01375rem;
}

.inputss {
  border-top: 1px solid gray;
}

.inputt {
  height: 4vw;
  width: 17vw;
  margin-inline-end: 1vw;
  margin-block: 1vw;
  border-radius: 10px;
}

.latestProjects {
  border: 1px solid gray;
  margin: 10px;
  padding: 10px;
  border-radius: 20px;
}

.pnth {
  display: flex;
  gap: 0.5vw;
}

.todayCon {
  display: flex;
  gap: 1vw;
  flex-wrap: wrap;
  border-bottom: 1px solid black;
  padding-block: 2vw;
}

.todayCon:last-child {
  border-bottom: none;
}

@media (max-width: 768px) {
  .todayCon {
    display: block;
  }
}

.topics {
  display: flex;
  gap: 1vw;
}

.dropdown {
  position: relative;
}

.dropdown button {
  width: 100%;
  max-width: 30rem;
  max-height: 4.5rem;
  background-color: #ffffff;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  border: 2px solid #f4cfd5;
  display: flex;
  justify-content: space-between;
  color: #6b6b6b;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  letter-spacing: -0.01375rem;
  padding-left: 1rem;
  margin-bottom: 10px;
}

.dropdown svg {
  margin-top: 15px;
  margin-right: 10px;
  padding: 0;
  display: block;
}

.dropdownMenu {
  position: absolute;
  left: 0;
  border: 1px solid #f4cfd5;
  border-radius: 12px;
  width: 27rem;
  padding-left: 8px;
  margin: 0;
  cursor: pointer;
  background-color: #ffffff;
  z-index: 10;
}

.dropdownMenu ul {
  padding: 0;
}

.dropdownMenu li {
  padding: 5px;
  font-size: 1rem;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  letter-spacing: -0.01375rem;
  list-style: none;
}

.dropdownMenu li:not(:last-child) {
  border-bottom: 1px solid #f4cfd5;
}

.dropdownMenu li:hover {
  background-color: #f4cfd5;
}

.dropdownMenu li.selected {
  background-color: #f4cfd5;
}

select {
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
}

select option {
  padding: 10px;
}

/* Part Two: Selected projects styles */
.selectionContainer {
  display: inline-flex;
  margin-top: 10px;
  margin-bottom: 10px;
  gap: 0.5rem;
}

.availableProjectButton {
  display: inline-flex;
  flex: 1;
  padding: 0.5rem 2rem;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #d9d9d9;
  color: #c50d2e;
  background-color: #ffffff;
  font-size: 1rem;
  gap: 1rem;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  text-align: center;
}

.availableProjectButton.active {
  background-color: #f4cfd5;
  color: rgb(216, 15, 15);
}

.slotDurationContainer {
  border-top: 1px solid gray;
  margin-top: 2em;
}

.slotDuration {
  margin-bottom: 20px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.slotDuration button {
  padding: 8px 24px;
  gap: 8px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  background-color: white;
  color: rgb(216, 15, 15);
}

.slotDuration button.active {
  background-color: #f4cfd5;
  color: rgb(216, 15, 15);
}

.dayoff {
  display: flex;
  align-items: center;
  font-size: 16px;
}

/* Hide the default checkbox */
.dayoff input[type="checkbox"] {
  display: none;
}

/* Style the custom checkbox */
.dayoff .customCheckbox {
  width: 16px;
  height: 16px;
  border: 2px solid #ccc;
  border-radius: 4px;
  display: inline-block;
  margin-right: 8px;
  position: relative;
}

/* Style the custom checkbox when checked */
.dayoff input[type="checkbox"]:checked + .customCheckbox::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  background-color: rgb(216, 15, 15);
}

/* Part Four: Add and Reset buttons styles */
.addResetButtons button {
  font-size: 16px;
  width: 150px;
  cursor: pointer;
  padding: 8px 48px;
  cursor: pointer;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  margin-right: 40px;
}

.addResetButtons button:first-child {
  background-color: #111;
  color: #fff;
}

.addResetButtons button:last-child {
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #ccc;
}

.table {
  margin-top: 20px;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
}

table {
  width: 100%;
  border-collapse: collapse;
  flex-shrink: 0;
  border: 2px solid #ddd;
  border-radius: 12px;
  margin-bottom: 1em;
}

th,
td {
  border: 1px solid #ddd;
  padding-left: 2em;
  color: #111;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 200;
  line-height: 150%;
  letter-spacing: -0.22px;
  text-align: left;
  width: 1070px;
  height: 50px;
}

th {
  background: #f4cfd5;
}

td {
  color: black;
}

.day-off-selected {
  background-color: #eee;
}

table.day-off-selected {
  background-color: #eee;
}

.scrollableTable button {
  border: none;
  font-size: 16px;
  cursor: pointer;
  background-color: white;
}

.submitButton {
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
  margin-top: 2vw;
  overflow: hidden;
}

.submitButton button {
  padding: 0.7em;
  min-width: 26em;
  border: 1px solid #ccc;
  border-radius: 12px;
  font-weight: 700;
  font-size: medium;
  background: #280404;
  color: #fff;
  cursor: pointer;
}

.submitButton button:disabled {
  opacity: 0.5;
}

.dateSelector {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popupCard {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: flexbox;
  z-index: 1001;
}

.popupCard h2 {
  margin-bottom: 40px;
}

.popupContent {
  display: flex;
  flex-direction: column;
}

.hoursInputContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.hoursInputContainer input {
  padding-left: 0.7em;
  padding-right: 0.7em;
  padding-top: 0.7em;
  padding-bottom: 0.7em;
  min-width: 25em;
  border: 2px solid #f4cfd5;
  border-radius: 12px;
  font-weight: 500 !important;
  font-size: medium;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.10196078431372549);
}

.hoursInputContainer label {
  margin-right: 10px;
}

.descriptionContainer {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.descriptionContainer textarea {
  padding-left: 0.7em;
  padding-right: 0.7em;
  padding-top: 0.7em;
  padding-bottom: 0.7em;
  width: 30em;
  min-width: 30em;
  border: 2px solid #f4cfd5;
  border-radius: 12px;
  font-weight: 500 !important;
  font-size: medium;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.10196078431372549);
}

.descriptionContainer label {
  margin-right: 10px;
}

.popupActions {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.popupActions button {
  font-size: 16px;
  width: 150px;
  cursor: pointer;
  padding: 8px 48px;
  cursor: pointer;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  margin-right: 40px;
}

.popupActions button:first-child {
  background-color: #111;
  color: #fff;
}

.popupActions button:last-child {
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #ccc;
}

.center {
  margin-top: 6vw;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.todo input {
  width: 50%;
  height: 3vw;
  border-radius: 20px;
}

.slotDurationContainer textarea {
  width: 90%;
  padding: 1vw;
  font-size: 16px;
}

.todo {
  margin-top: 1vw;
  border-top: 1px solid gray;
}

.reasonForRestLabel {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  padding: 8px;
}

.reasonForRestInput {
  display: block;
  font-weight: bold;
  margin-bottom: 15px;
  padding: 8px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@media (max-width: 767px) {
  .todo input {
    width: 90%;
    height: 6vw;
  }

  .contentCard {
    margin-top: 100px;
    margin-left: 1em;
    margin-right: 1em;
    width: 100%;
  }

  .daySelector {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    align-items: center;
    margin-top: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid #dadada;
    border-radius: 12px;
    flex-direction: column;
  }
  .daySelector button {
    width: 90%;
    display: block;
    background: #ccc;
  }

  .ToDo {
    padding: 5px;
    border: 1px solid #dadada;
    border-radius: 12px;
  }

  .ToDo h2 {
    color: #111;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.01375rem;
  }

  .dropdownMenu {
    position: absolute;
    border: 1px solid #ccc;
    border-radius: 12px;
    width: 100%;
    max-width: 20rem;
  }

  .slotDuration {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.8rem;
  }

  .slotDuration h2 {
    margin: 0 0 10px;
  }

  .slotDuration button {
    padding: 5px;
    font-size: 12px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 12px;
    background-color: #fcfcfc;
    color: rgb(216, 15, 15);
    flex: 1;
    padding: 25px;
  }

  .addResetButtons button {
    font-size: 14px;
    width: 93%;
    margin-bottom: 5px;
    cursor: pointer;
  }

  .hoursInputContainer input {
    padding-left: 0.7em;
    padding-right: 0.7em;
    padding-top: 0.7em;
    padding-bottom: 0.7em;
    min-width: 10em;
    border: 2px solid #f4cfd5;
    border-radius: 12px;
    font-weight: 500 !important;
    font-size: medium;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.10196078431372549);
  }

  .descriptionContainer {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .descriptionContainer textarea {
    padding-left: 0.7em;
    padding-right: 0.7em;
    padding-top: 0.7em;
    padding-bottom: 0.7em;
    width: 10em;
    border: 2px solid #f4cfd5;
    border-radius: 12px;
    font-weight: 500 !important;
    font-size: medium;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.10196078431372549);
  }

  .descriptionContainer label {
    margin-right: 10px;
  }

  .popupActions {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .submitButton button {
    min-width: 100%;
  }
}
