.retainerProjects {
  margin-inline-start: 250px;
  padding-block: 1vw;
}

.retainerProjects h3 {
  margin-bottom: 20px;
}

.project-item {
  list-style: none;
  margin-bottom: 15px;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
}

.project-item h2 {
  margin-top: 0;
  margin-bottom: 5px;
}

.project-item button {
  display: inline-flex;
  flex: 1;
  padding: 0.5rem 2rem;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #D9D9D9;
  color: #C50D2E;
  background-color: #ffffff;
  font-size: 1rem;
  gap: 1rem;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  text-align: center;
}

.project-item button:hover {
  background-color: #F4CFD5;
  color: rgb(216, 15, 15);
}

/* Responsive styles for mobile screens */
@media (max-width: 768px) {
  .retainerProjects {
    margin-inline-start: 0; /* Remove left margin on smaller screens */
    padding-block: 2vw; /* Increase padding for smaller screens */
  }

  .retainerProjects h3 {
    margin-bottom: 15px; /* Adjust margin for smaller screens */
    font-size: 1.2rem; /* Adjust font size for smaller screens */
  }

  .project-item {
    margin-bottom: 10px; /* Reduce margin for smaller screens */
    padding-bottom: 8px; /* Reduce padding for smaller screens */
  }

  .project-item h2 {
    margin-top: 0;
    margin-bottom: 4px; /* Reduce margin for smaller screens */
    font-size: 1.1rem; /* Adjust font size for smaller screens */
  }

  .project-item button {
    flex-direction: column; /* Stack button elements vertically */
    padding: 0.5rem; /* Adjust padding for smaller screens */
    font-size: 0.9rem; /* Adjust font size for smaller screens */
    margin-right: 0; /* Remove right margin */
  }

  .project-item button:hover {
    background-color: #F4CFD5;
    color: rgb(216, 15, 15);
  }
}