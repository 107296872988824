.container {
  margin-inline-start: 250px;
  padding-block: 1vw;
}

.contentCard {
  margin-block: 3vw;
  border: 1px solid gray;
  border-radius: 10px;
  width: 80%;
  margin-inline: auto;
  padding: 2vw;
}

.contentCon {
  display: flex;
  flex-direction: column;
  gap: 0.3vw;
  margin-inline: auto;
  border: 1px solid gray;
  border-radius: 10px;
  width: 90%;
}

.content {
  display: flex;
  /* width: ; */
  justify-content: center;
  align-items: center;
  gap: 2vw;
}

.content select {
  width: 50% !important;
}

.content input {
  height: 3vh;
}

.sentenceCon {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin-inline: auto;
}

.dates {
  display: flex;
  align-items: center;
  gap: 1vw;
}

.desc {
  /* margin-inline: auto; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2vw;
  margin-block: 1vw;
}

.desc input {
  height: 8vh;
  width: 70%;
}

.upload {
  display: flex;
  justify-content: center;
  gap: 2vw;
  margin-block: 1vw;
}

.contentCon .contentCard {
  /* margin-inline-start: 0vw; */
  border: none;
  padding: none;
}

.title {
  text-align: center;
}

.availableProjectButton {
  display: inline-flex;
  flex: 1;
  padding: 0.5rem 2rem;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #d9d9d9;
  color: #c50d2e;
  background-color: #ffffff;
  font-size: 1rem;
  gap: 1rem;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  text-align: center;
}

.availableProjectButton.active {
  background-color: #f4cfd5;
  color: rgb(216, 15, 15);
}

.already {
  display: inline-flex;
  flex: 1;
  padding: 0.5rem 2rem;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #d9d9d9;
  color: white;
  background-color: rgb(94, 94, 90);
  font-size: 1rem;
  gap: 1rem;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  text-align: center;
}

@media (max-width: 768px) {
  .container {
    margin-inline-start: 0px;
    padding-top: 25vw;
  }

  .contentCard {
    margin-top: 11vw !important;
    margin-inline-start: unset;
    margin-inline: auto;
    width: 90%;
    display: block !important;
    margin-bottom: 10vw;
  }

  .projectCompletion .contentCard .sel {
    width: 100% !important;
  }

  .sentenceCon {
    display: unset;
    width: unset;
    margin-bottom: 5vw;
  }
}

/* .submitButton {
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
  margin-top: 2vw;
  overflow: hidden;
}

.submitButton button {
  padding: 0.7em;
  min-width: 26em;
  border: 1px solid #ccc;
  border-radius: 12px;
  font-weight: 700;
  font-size: medium;
  background: #280404;
  color: #fff;
  cursor: pointer;
}

.submitButton button:disabled {
  opacity: 0.5;
  min-width: 100%;
} */

.projectCompletion .contentCard .sel {
  /* width: 35%; */
  border: 1px solid black;
}

.projectCompletion .contentCard {
  display: flex;
  justify-content: space-between;
}
