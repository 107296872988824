.container {
  margin-inline-start: 250px;
  padding-block: 1vw;
}

@media (max-width: 1024px) {
  .container {
      margin-inline-start: 150px;
  }
}

@media (max-width: 768px) {
  .container {
      margin-inline-start: 20px;
      padding-block: 2vw;
  }
}

/* General form styling */
form {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

form > div {
  margin-bottom: 15px;
}

form label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
  padding: 0.5em;
}

form input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

form input:focus {
  border-color: #f4436c;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
.btn{
  display: flex; 
  justify-content: center; 
}
form button[type="submit"] {
 
  margin-top: 2vw;
  padding: 0.7em;
  min-width: 26em;
  border: 1px solid #ccc;
  border-radius: 12px;
  font-weight: 700;
  font-size: medium;
  background: #280404;
  color: #fff;
  cursor: pointer;
  position: relative;
}

/* Table styling */
.excelTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.excelTable th,
.excelTable td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  white-space: nowrap;
  font-size: 16px;    
}

.excelTable th {
  background-color: #f2f2f2;
  color: #c20a0a;
}

.excelTable td {
  background-color: #FFFFFF; /* White background for cells */
}

.excelTable tr:nth-child(even) td {
  background-color: #E6E6E6; /* Light gray for alternating rows */
}

.excelTable tr:hover td {
  background-color: #ADD8E6; /* Light blue hover effect */
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; /* Space between buttons */
  margin-top: 20px;
}

.pagination button {
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #c20a0a;
}

.pagination button:hover {
  background-color: #ddd;
}

.pagination button.active {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
  font-weight: bold;
}

/* Responsive design */
@media (max-width: 768px) {
  form {
      padding: 15px;
  }

  .excelTable th,
  .excelTable td {
      font-size: 12px;
      padding: 6px;
  }
}

@media (max-width: 480px) {
  form {
      padding: 10px;
      border: none;
      box-shadow: none;
  }

  form > div {
      margin-bottom: 10px;
  }

  .excelTable th,
  .excelTable td {
      font-size: 10px;
      padding: 4px;
  }

  .container {
      margin-inline-start: 10px;
  }
}
