.container {
  margin-inline-start: 250px;
  padding-top: 30px;
}

.title {
  text-align: center;
}

.box {
  width: 90%;
  padding-top: 30px;
  margin-inline: auto;
  border: 1px solid black;
  border-radius: 20px;
  padding: 20px;
}

.selection {
  /* width: 30%; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid gray;
}

.selection:last-child {
  border-bottom: none;
}

.sel {
  width: 50%;
}

.submitButton {
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
  margin-top: 2vw;
  width: 50%;
  margin-inline: auto;
}

.submitButton button {
  padding: 0.7em;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 12px;
  font-weight: 700;
  font-size: medium;
  background: #280404;
  color: #fff;
  cursor: pointer;
}

.submitButton button:disabled {
  opacity: .5;
}

@media (max-width:768px) {
  .container {
    margin-inline: auto;
    padding-top: 150px;
  }

  .box {
    width: 80% !important;
    padding: 10px !important;
  }

  .selection {
    flex-direction: column;
    gap: 2vw;
  }
}

/* Table Container */
.tableContainer {
  margin-inline-start: 20px;
  margin-bottom: 50px;
  padding: 0 10px;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

/* Table Styles */
/* General Styles */
.dateSelector {
  margin: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  font-family: Poppins, sans-serif;
}

.toggleIcon {
  width: 23px;
  height: 23px;
  margin-left: 10px;
  cursor: pointer;
  vertical-align: middle;
}

.dateSelector label {
  font-weight: bold;
  font-size: 16px;
}

.dateSelector select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: border-color 0.3s;
}

.dateSelector select:focus {
  border-color: #007bff;
  outline: none;
}

/* Table Container */


/* Table Styles */
.tableCaption {
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: bold;
  font-family: Poppins;
  text-align: center;
  color: rgb(10, 10, 10);
  cursor: pointer;
}

.table {
  width: 100%;
  border-collapse: collapse;
  position: relative;
}

.table th,
.table td {
  padding: 4px;
  text-align: center;
  border: 1px solid #ddd;
  font-family: Poppins;
}

.table th {
  background-color: #f2f2f2;
}

/* Responsive Styles */

/* Medium screens (max-width: 1200px) */
@media (max-width: 1200px) {
  .dateSelector {
    flex-direction: column;
    gap: 10px;
  }

  .dateSelector label {
    font-size: 14px;
  }

  .dateSelector select {
    width: 100%;
  }

  .tableContainer {
    margin-inline-start: 10px;
  }
}

/* Small screens (max-width: 768px) */
@media (max-width: 768px) {
  .dateSelector {
    margin: 10px;
    gap: 5px;
  }

  .dateSelector label {
    font-size: 12px;
  }

  .dateSelector select {
    padding: 8px;
    font-size: 12px;
    width: 100%;
    /* Full width on small screens */
  }

  .table {
    font-size: 14px;
  }

  .table th,
  .table td {
    padding: 4px;
  }

  .tableCaption {
    font-size: 14px;
  }

  .monthContainer {
    flex-direction: column;
    align-items: flex-start;
  }
}

/* Very small screens (max-width: 480px) */
@media (max-width: 480px) {
  .dateSelector {
    font-size: 12px;
  }

  .dateSelector select {
    padding: 6px;
    font-size: 10px;
  }

  .table {
    font-size: 12px;
  }

  .table th,
  .table td {
    padding: 2px;
  }

  .tableCaption {
    font-size: 12px;
  }

  .monthContainer {
    flex-direction: column;
    align-items: flex-start;
  }
}

/* Module-specific styles */
.selectInput {
  margin: 10px 0;
  padding: 8px;
  width: 250px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-family: Poppins;
}

.dateRange {
  margin-top: 20px;
}

.dateRange p {
  font-size: 18px;
  font-weight: bold;
}