.container {
    margin-inline-start: 250px;
    padding-block: 1vw;
  }
  
  div {
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
  }
  
  .formRow {
    display: flex;
    align-items: center;
    gap: 10px;  
    margin-bottom: 15px;
    flex-wrap: wrap;  
  }
  
  label {
    text-align: left;
    flex: 0 0 150px;  
  }
  
  select, 
  input {
    flex: 1;  
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .container {
      margin-inline-start: 0; /* Remove left margin for small screens */
      padding-block: 2vw; /* Adjust padding for better spacing */
    }
  
    .formRow {
      flex-direction: column; /* Stack label and input/select vertically */
      align-items: flex-start; /* Align items to the left */
      gap: 5px; /* Reduce gap for compact layout */
    }
  
    label {
      flex: 0 0 auto; /* Allow label to adjust based on content */
      width: 100%; /* Ensure label takes full width */
    }
  
    select, 
    input {
      width: 100%; /* Make input/select full width */
    }
  }
  
  @media (max-width: 480px) {
    .container {
      padding-block: 3vw; /* Further adjust padding for very small screens */
    }
  
    .formRow {
      gap: 2px; /* Minimize gap for compact spacing */
      margin-bottom: 10px; /* Reduce margin between rows */
    }
  
    label {
      font-size: 14px; /* Reduce font size for small screens */
    }
  
    select, 
    input {
      font-size: 14px; /* Adjust font size for inputs/selects */
      padding: 6px; /* Reduce padding */
    }
  }
  