
.container {
  margin-inline-start: 250px;
  padding-block: 1vw;
}
.contentCard {
  margin-block: 3vw;
  border: 1px solid gray;
  border-radius: 10px;
  width: 80%;
  margin-inline: auto;
  padding: 2vw;
}

/* Title styles */
.contentCard p {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px; 
  color: #080808;  
  text-align: left; 
}

/* Progress bar container */
.progress-bar {
  display: flex;
  align-items: center;
  height: 30px;  
  margin-bottom: 10px;  
   
  border-radius: 5px;  
  border: 1px solid gray;
  overflow: hidden;  
  text-align: center;
}



/* Progress indicator */
.progress-bar div{
  height: 100%;
  background-color: #c20a0a;   /* Primary color for progress bar */
  border-radius: 5px;
  transition: width 0.3s ease-in-out;
  text-align: center; /* Center align text inside progress bar */
  line-height: 30px; /* Vertically center text */
  color: white;  /* Text color inside progress bar */
  font-weight: bold; /* Make text bold for better visibility */
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .container {
    padding: 15px; /* Reduced padding on smaller screens */
  }

  .title {
    font-size: 16px; /* Slightly smaller font size */
  }

  .card {
    padding: 15px; /* Reduced padding in cards */
  }

  .progress-bar {
    height: 25px; /* Adjust height on smaller screens */
  }

  .progress-bar p {
    font-size: 12px; /* Smaller font size for text */
    margin: 0 10px; /* Adjusted spacing for text */
  }

  .progress {
    line-height: 25px; /* Adjust line height for smaller progress bars */
  }
}

.excelTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.excelTable th,
.excelTable td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.excelTable th {
  background-color: #f2f2f2;
    color:#c20a0a; 
}

.excelTable td {
  background-color: #FFFFFF; /* White background for cells */
}

.excelTable tr:nth-child(even) td {
  background-color: #E6E6E6; /* Light gray for alternating rows */
}

.excelTable tr:hover td {
  background-color: #ADD8E6; /* Light blue hover effect */
}
